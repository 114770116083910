.row-col {
  align-items: center;
  justify-content: center;
}
.menu {
  align-items: center;
  justify-content: center;
}
.layout {
  width: 100% !important;
  height: 100% !important;
 
  ;
}
.site-layout{
 background-color: beige;
  background-size: cover;
 
}
