@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
body {
  height: 100% !important;
  width: 100% !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

{
  background-color: black;
}
/* @keyframes changewidth {
  from {
    position: absolute;
    top: 0px;
  }

  to {  
    top: -25%;
    position: absolute;
   
  }
}
.customSelect {
    animation-duration: 5s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    
  
} */
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.text1 {
  text-overflow: ellipsis;
}
.title-h4 {
  color: #ff4d2e;
}
.title {
  padding-top: 10px;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.div-space {
  line-height: 2.2;
}

.text2 {
  padding: 5px;
}

.contentStyle {
  height: 160px;
  color: #fff;
  line-height: 160px;
  text-align: center;
  background: #364d79;
}

.title {
  text-align: center;
}
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

body {
  margin: 0;

  background-repeat: no-repeat;
  background-size: cover;
}
div#root {
  height: 100%;
}
section.login-block {
  height: 100%;
}

.login-block {
  background: #de6262; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffb88c,
    #de6262
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
}
.banner-sec {
  background: url(https://static.pexels.com/photos/33972/pexels-photo.jpg)
    no-repeat left bottom;
  background-size: cover;
  min-height: 500px;
  border-radius: 0 10px 10px 0;
  padding: 0;
}
.container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}
.carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #feb58a;
}
.login-sec .copy-text a {
  color: #e36262;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #de6262;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #feb58a;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #de6262;
  color: #fff;
  font-weight: 600;
}
.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}
.banner-text h2 {
  color: #fff;
  font-weight: 600;
}
.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}
.banner-text p {
  color: #fff;
}

.row-col {
  align-items: center;
  justify-content: center;
}
.menu {
  align-items: center;
  justify-content: center;
}
.layout {
  width: 100% !important;
  height: 100% !important;
 
  ;
}
.site-layout{
 background-color: beige;
  background-size: cover;
 
}

.left {
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  position: relative;
}
.right-header {
  align-items: right;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
  background-image: url(/static/media/banner1.3c165643.jpg);
  background-blend-mode: darken;
  background-size: cover;
}
.menu {
  font-size: large !important;
  font-weight: 500;
  line-height: 64px;
  color: #eb4034 !important;
}
.bvvtn {
  margin-top: 15px;
  color: #0d05fa;
  font-weight: 700;
  font-size: x-large;
  height: 10%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.text {
  font-size: medium;
  font-weight: 500;
  font-family: "Segoe UI", Tahoma;
  padding: 10px;
}
.hello {
  margin-top: 15px;
  font-weight: 400;
  color: #eb4034;
  font-size: medium;
  height: 10%;
  float: right;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.img {
  background-image: url(/static/media/logobv.85f17803.png);
  background-repeat: no-repeat;
  background-size: contain;
}

