.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.text1 {
  text-overflow: ellipsis;
}
.title-h4 {
  color: #ff4d2e;
}
.title {
  padding-top: 10px;
  height: 60px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.div-space {
  line-height: 2.2;
}

.text2 {
  padding: 5px;
}
